@import "~@bit/hlouzek.rhplus.jumbo-styles/dist/styles/global/_variables.scss";

$warning-dark: darken($warning, 10);

// .state-cancel
// {

// }

.state-rejected,
.state-printed-held,
.state-awaiting-printing-held
{
  color: $danger;
  text-align: center;
}

.state-received {
  color: $success;
  text-align: center;

}

.state-completed {
  color: black;
  background-color: #beff9e;
  text-align: center;
}

.state-sent {
  color:$warning-dark;
  text-align: center;

}

.state-printed {
  color: $warning;
  text-align: center;

}

.fg-canceled {
  color: lighten($danger, 20%);
  // background-color: lighten($light-gray, 40%) !important;
  text-align: center;
}

.orders-filter {
  z-index: 100000;
  position: absolute;
  right: 0px;
  margin-top: 12px;
  margin-right: 20px;
  text-align: right;
}
