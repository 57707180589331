@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?tjpx3l');
  src:  url('../fonts/icomoon.eot?tjpx3l#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?tjpx3l') format('truetype'),
    url('../fonts/icomoon.woff?tjpx3l') format('woff'),
    url('../fonts/icomoon.svg?tjpx3l#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="iconmoon-"], [class*=" iconmoon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font: normal normal normal 14px/1 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icomoon-md {
  margin: 0.2em;
  font-size: 1.1em;
}

.icomoon-lg {
  font-size: 1.33333333em;
  /* line-height: 0.75em; */
  /* vertical-align: -15%; */
}
.icomoon-2x {
  font-size: 2em;
}
.icomoon-3x {
  font-size: 3em;
}
.icomoon-4x {
  font-size: 4em;
}
.icomoon-5x {
  font-size: 5em;
}
.icomoon-fw {
  width: 1.28571429em;
  text-align: center;
} 

.iconmoon-pdf:before {
  content: "\e900";
  color: rgb(226, 87, 76);
}
.iconmoon-pdf .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(181, 54, 41);
}
.iconmoon-pdf .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(61, 179, 158);
}
.iconmoon-pdf .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.iconmoon-pdf .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
