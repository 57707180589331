

.rhplus-md {

  margin: 0.2em;

  height: 1.1em;

}



.rhplus-lg {

  height: 1.33333333em;

  /* line-height: 0.75em; */

  /* vertical-align: -15%; */

}

.rhplus-2x {

  height: 2em;

}

.rhplus-3x {

  height: 3em;

}

.rhplus-4x {

  height: 4em;

}

.rhplus-5x {

  height: 5em;

}

.rhplus-fw {

  width: 1.28571429em;

  text-align: center;

} 



.rhplus-icon-tale {

  box-shadow: 0 5px 6px rgba(65, 64, 64, 0.2);

  margin: 15px;

}



.rhplus-icon-img {

  margin-top: 10px;

}



.rhplus-icon-title {

  font-weight: lighter;

  font-size: 10px;

}



