
.purchase-order-summary .supplier-group {
  background-color: lighten(grey, 40%) !important;
  text-align: center;
}

.purchase-order-summary .sage-group {
  background-color: lighten(#95EB42, 37%) !important;
  text-align: center;
}

.purchase-order-summary .invoiced-grey {
  background-color: lighten(grey, 50%) !important;
  text-align: center;
}
