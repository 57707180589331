@import "~@bit/hlouzek.rhplus.jumbo-styles/dist/styles/global/_variables.scss";

.report-items-filter {
  z-index: 100000;
  position: absolute;
  right: 0px;
  margin-top: 12px;
  margin-right: 20px;
  text-align: right;
}
